<template>
  <div class="container_box">
    <vHeader :showNav="false"></vHeader>
    <div class="content_box" v-if="guidanceContent">
      <!--预览pdf文件-->
      <div class="pdf_boxs" v-if="guidanceContent.type === 1">
        <iframe :src="guidanceContent.pdfUrl" frameborder="0" :style="iframeStyle"></iframe>
      </div>
      <!--预览在线内容-->
      <template v-if="guidanceContent.type === 3">
        <div class="preview_online_content_box">
          <div class="copy_box" @click="copyBtn">{{ $t('key1000000') }}</div>
          <div class="online_content_box" v-viewer="viewerOptions" ref="scrollContainer" v-html="guidanceContent.content"></div>
        </div>
        <!--右侧的锚点导航-->
        <anchorNavigation ref="anchorNav" setTop="74px" setRight="210px" :headerHeight="55"></anchorNavigation>
      </template>
    </div>
    <!--返回顶部-->
    <BackTop :bottom="60"></BackTop>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import vHeader from "@/components/layout/header.vue";
import anchorNavigation from "@/components/common/anchorNavigation.vue";
import {commonCopyContent, setLinkPath} from "@/utils/common";

export default {
  name: "viewGuideContent",
  mixins: [Mixin],
  data() {
    return {
      guidanceContent: null,
      iframeStyle: {
        width: '100%',
        minHeight: 'calc(100vh - 57px)',
        border: 'none',
      }
    }
  },
  created() {
    this.$webSocket.initWebSocket();
    this.getSystemOperationGuidelinesData();
  },

  methods: {
    // 获取对应系统的操作指引数据
    getSystemOperationGuidelinesData() {
      let v = this;
      v.guidanceContent = null;
      let ymsOperatingInstructionsId = v.$route.query.ymsOperatingInstructionsId;
      v.axios.get(api.get_ymsOperatingInstructions_api + ymsOperatingInstructionsId, null, {loading: true}).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          if (data) {
            if (data.status === 1) {
              // pdf文件
              if (data.type === 1) {
                data.pdfUrl = setLinkPath(data.content);
              }
              // 在线内容
              if (data.type === 3) {
                data.content = v.setImageStyle(data.content);
              }
              v.guidanceContent = data;
              v.scrollTopFun();
              if (data.type === 3) {
                v.$nextTick(() => {
                  setTimeout(() => {
                    const content = v.$el.querySelector('.online_content_box');
                    let dom = v.$refs['scrollContainer'];
                    v.$refs['anchorNav'].getAnchorsFromContent(dom, content);
                  }, 1000);
                });
              }
            } else {
              v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000003'));
              return false;
            }
          } else {
            v.$Message.warning(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000003'));
            return false;
          }
        }
      });
    },
    // 动态设置渲染图片的大小的样式
    setImageStyle(html) {
      // 创建一个临时div用于解析HTML内容
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      // 获取解析后的img标签集合
      const images = tempDiv.getElementsByTagName('img');
      Array.from(images).forEach(image => {
        // 设置图片样式
        image.style.maxWidth = '928px';
        image.style.height = 'auto';
      });
      // 返回更新后的HTML内容
      return tempDiv.innerHTML;
    },
    // 复制
    copyBtn() {
      let href = window.location.href;
      let content = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000001')}${this.guidanceContent.title}\n` + `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000002')}${href}`;
      if (content) {
        commonCopyContent(content, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000004'));
      }
    }
  },
  components: {
    anchorNavigation,
    vHeader
  }
}
</script>

<style lang="less">
.container_box {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  background-color: #F1F1F1;

  .content_box {
    padding-top: 50px;

    .preview_online_content_box {
      margin: 24px auto;
      width: 928px;
      background-color: #fff;
      position: relative;

      .copy_box {
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 10;
        cursor: pointer;
      }
    }

    .online_content_box {
      width: 100%;
      padding: 20px;

      img {
        max-width: 888px !important;
      }

      p, p > span {
        text-wrap: inherit !important;
        word-wrap: break-word !important;
        word-break: break-all !important;
      }
    }
  }
}
</style>